import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormBulkUploadJob } from '@se/data/forms/types.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';

export type FormBulkUploadJobHistoryState = {
  currentPage: number;
  error: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  jobs: FormBulkUploadJob[];
  orderBys: OrderBy[];
};

const DEFAULT_STATE: FormBulkUploadJobHistoryState = {
  currentPage: 1,
  error: false,
  hasNextPage: false,
  isLoading: false,
  isLoadingMore: false,
  jobs: [],
  orderBys: [
    {
      fieldName: 'createdAt',
      direction: 'Descending',
    },
  ],
};

const slice = createSlice({
  name: 'libs/forms/formBulkUploadJobHistory',
  initialState: DEFAULT_STATE,
  reducers: {
    loadJobs(state, action: PayloadAction<{ loadNextPage: boolean }>) {
      state.error = false;
      state.isLoading = !action.payload.loadNextPage;
      state.isLoadingMore = action.payload.loadNextPage;
    },
    loadedJobs(
      state,
      action: PayloadAction<{
        currentPage: number;
        hasNextPage: boolean;
        jobs: FormBulkUploadJob[];
      }>,
    ) {
      const { jobs, hasNextPage, currentPage } = action.payload;
      state.error = false;
      state.isLoading = false;
      state.isLoadingMore = false;
      state.currentPage = currentPage;
      state.hasNextPage = hasNextPage;
      if (currentPage === 1) state.jobs = jobs;
      else state.jobs.push(...jobs);
    },
    loadJobsFailed(state) {
      state.error = true;
      state.isLoading = false;
      state.isLoadingMore = false;
    },
  },
});

export const { loadJobs, loadedJobs, loadJobsFailed } = slice.actions;
export const reducer = slice.reducer;
export type State = FormBulkUploadJobHistoryState;
