import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import {
  GlobalReduxEpicDependenciesType,
  GlobalReduxStateType,
} from '@seeeverything/ui.util/src/redux/types.ts';
import {
  TypedUseSelectorHook,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import { automatedActionSlice } from './automatedAction/index.ts';
import { automatedActionConfigurationSlice } from './automatedActionConfiguration/index.ts';
import { DistributionListState } from './distributionList/distributionListSlice.ts';
import { EditDistributionListState } from './editDistributionList/editDistributionListSlice.ts';
import { editGoalScheduleSlice } from './editGoalSchedule/index.ts';
import { editInstanceScheduleSlice } from './editInstanceSchedule/index.ts';
import { IFormDigitalContentState } from './form-digital-content/types.ts';
import { IFormFileState } from './form-file/types.ts';
import { IFormInstanceState } from './form-instance/types.ts';
import { IFormScoreState } from './form-score/types.ts';
import { formActionSlice } from './formAction/index.ts';
import { formActionPlanSlice } from './formActionPlan/index.ts';
import { formBulkUploadClickThroughSlice } from './formBulkUploadClickThrough/index.ts';
import { formBulkUploadDownloadTemplateSlice } from './formBulkUploadDownloadTemplate/index.ts';
import { formBulkUploadImportFileSlice } from './formBulkUploadImportFile/index.ts';
import { formBulkUploadJobHistorySlice } from './formBulkUploadJobHistory/index.ts';
import { FormInstanceAppealState } from './formInstanceAppeal/formInstanceAppealSlice.ts';
import { goalScheduleSlice } from './goalSchedule/index.ts';
import { goalsActionsGridSlice } from './goalsActionsGrid/index.ts';
import { instanceScheduleSlice } from './instanceSchedule/index.ts';
import { issueSlice } from './issue/index.ts';

export type FormsRootState = {
  automatedAction: automatedActionSlice.State;
  automatedActionConfiguration: automatedActionConfigurationSlice.State;
  editGoalSchedule: editGoalScheduleSlice.State;
  editInstanceSchedule: editInstanceScheduleSlice.State;
  formActionPlanV2: formActionPlanSlice.State;
  formActionV2: formActionSlice.State;
  formBulkUploadClickThrough: formBulkUploadClickThroughSlice.State;
  formBulkUploadDownloadTemplate: formBulkUploadDownloadTemplateSlice.State;
  formBulkUploadImportFile: formBulkUploadImportFileSlice.State;
  formBulkUploadJobHistory: formBulkUploadJobHistorySlice.State;
  formDigitalContent: IFormDigitalContentState;
  formDistributionList: DistributionListState;
  formEditDistributionList: EditDistributionListState;
  formEditGoal: formEditGoalSlice.FormEditGoalState;
  formFile: IFormFileState;
  formGoalActionsGrid: goalsActionsGridSlice.State;
  formInstance: IFormInstanceState;
  formInstanceAppeal: FormInstanceAppealState;
  formIssue: issueSlice.State;
  formScore: IFormScoreState;
  goalSchedule: goalScheduleSlice.State;
  instanceSchedule: instanceScheduleSlice.State;
};

export type GlobalFormsState = GlobalReduxStateType<FormsRootState>;
export type GlobalFormsEpicDependencies = GlobalReduxEpicDependenciesType;

export const useFormsDispatch = () => useDispatch<any>();
export const useFormsSelector: TypedUseSelectorHook<GlobalFormsState> = (
  selector,
) => useSelector(selector, shallowEqual);
