/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionPlanAction } from '@se/data/forms/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { getCategoryOverdueLabel } from '../util.ts';
import { ActionContainer } from './ActionContainer.tsx';
import { AddAction } from './AddAction.tsx';
import { Category } from './Category.tsx';

export interface IStandaloneActionsContainerProps {
  isDefaultExpanded: boolean;
  instanceId: string;
  actions: FormActionPlanAction[];
  canCreateActions: boolean;
  standaloneActionsEnabled: boolean;
  typeFilter?: 'ONLY_NEW' | 'ONLY_EXISTING';
}

export const StandaloneActionsContainer: React.FC<
  IStandaloneActionsContainerProps
> = ({
  actions,
  canCreateActions,
  isDefaultExpanded,
  instanceId,
  standaloneActionsEnabled,
  typeFilter,
}) => {
  const goalCategoriesEnabled = useFormsSelector(
    (state) => state.tenantState.tenant.features.FORMS.GOAL_CATEGORIES,
  );

  const actionLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const overdueLabel = useMemo(() => {
    const numOverdueActions = actions.filter(
      (action) => action.status === 'Overdue',
    ).length;

    return numOverdueActions
      ? getCategoryOverdueLabel(0, numOverdueActions, actionLabel)
      : undefined;
  }, [actionLabel, actions]);

  const elActions = actions.map((action) => (
    <ActionContainer
      key={`StandaloneAction-${action.id}`}
      id={action.id}
      source={{ type: 'FORM', instanceId }}
    />
  ));

  const addNewActionText = useMemo(() => {
    return actions.length
      ? `Add another ${actionLabel}.`
      : `Add a new ${actionLabel}.`;
  }, [actionLabel, actions.length]);

  const elCreateNewAction = typeFilter !== 'ONLY_EXISTING' &&
    canCreateActions &&
    standaloneActionsEnabled && (
      <AddAction
        message={addNewActionText}
        instanceId={instanceId}
        style={!goalCategoriesEnabled && styles.addAction}
        showAutomatedActions={true}
      />
    );

  const elEmpty = actions.length === 0 &&
    !canCreateActions &&
    standaloneActionsEnabled && (
      <Text color={color.format(-0.5)} italic={true}>
        {`No existing ${str.plural(actionLabel)}.`}
      </Text>
    );

  if (!goalCategoriesEnabled)
    return actions.length ? (
      <>
        {elEmpty}
        {elActions}
        {elCreateNewAction}
      </>
    ) : (
      <>
        {elEmpty}
        {elCreateNewAction}
      </>
    );

  return (
    <Category
      id={'standaloneActions'}
      title={`Coaching Actions (${actions.length})`}
      overdueLabel={overdueLabel}
      isDefaultExpanded={isDefaultExpanded}
    >
      {elEmpty}
      {elActions}
      {elCreateNewAction}
    </Category>
  );
};

const styles = {
  addAction: css({
    padding: '10px 0 0 8px',
  }),
};
