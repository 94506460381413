import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { mergeDeepRightAll } from '../../object/object.ts';
import { str } from '../../str/index.ts';
import { DeepPartial, ModuleType } from '../../types.ts';
import { TenantConfiguration, TenantFeatures, TenantLocale } from './types.ts';

/**
 * Initial values for feature toggle flags.
 * These get overridden by tenant then subsequently can be overridden at
 * a module level.
 */
const DEFAULT_FEATURES: TenantFeatures = {
  DASHBOARDS: {
    FEEDBACK_PANEL: false,
    GRID_FOOTERS: false,
    TEMP_FILTERS: false,
  },
  FORMS: {
    ATTENDANCE: false,
    AUDIT: true,
    CAN_CREATE_STANDALONE_ACTIONS: false,
    CANCEL_INSTANCE: true,
    EXPORT_TO_PDF: true,
    GOAL_CATEGORIES: false,
    GOAL_NOTES: false,
    GOALS: true,
    PREVIOUS_ANSWERS: false,
    VALIDATION_SWITCH_IS_REQUIRED: true,
  },
  QUERY: {
    PROFILE: true,
    SETTINGS_SISENSE_URL_SELF_CONTAINED_TENANT: false,
    SETTINGS_FORM_SCHEDULES: false,
    SETTINGS_FORM_TEMPLATES: false,
    SETTINGS_GOAL_SCHEDULES: false,
    SETTINGS_ASSIGNED_TO_LINE_MANAGER: false,
  },
  ORG_HIERARCHY_EDITOR: {
    TEAMS: {
      VIEW: true,
      UPDATE: true,
      DELETE: true,
      ADD: true,
    },
    PEOPLE: {
      VIEW: true,
      UPDATE: true,
      DELETE: true,
      ADD: true,
      IMPORT: false,
    },
  },
};

/**
 * Having trouble with "[sprintf] unexpected placeholder"? Check your
 * placeholder terminates with a type specifier, e.g. '%(myString)s'
 */
export const DEFAULT_LOCALE: TenantLocale = {
  label: {
    action: 'action',
    issueAction: 'Coaching Action',
    actionNote: 'Notes',
    form: 'Form',
    formAssignedTo: 'Coach',
    formSubject: 'Coachee',
    schedules: 'Scheduler',
    team: 'Branch/Team',
  },
  error: {
    formLoadDefault:
      'There was a problem loading your form. Please contact your support team.',
    formLoadRefresh:
      'There was a problem loading your form. Click here and we will try to reload your form.',
    formCreateRetry:
      'There was a problem creating your form. Click here and we will try to create it again.',
  },
  auditing: {
    forms: {
      action: {
        ActionCancelled: 'cancelled %(locale.label.action)s',
        ActionCompleted: 'completed %(locale.label.action)s',
        ActionCreated: 'created %(locale.label.action)s',
        ActionReverted: 're-opened %(locale.label.action)s',
        ActionDescriptionChanged: `changed description to '%(description)s'`,
        ActionIssueChanged: ({ issueId }) =>
          issueId
            ? `linked issue to %(locale.label.action)s`
            : `removed issue from %(locale.label.action)s`,
        ActionGoalChanged: ({ goalId }) =>
          goalId
            ? `linked goal to %(locale.label.action)s`
            : `removed goal from %(locale.label.action)s`,
        ActionAnswerChanged: ({ questionType }) =>
          questionType === 'Dropdown'
            ? `changed answer for "%(questionLabel)s" to %(selectedOptionLabels)s`
            : `changed answer for "%(questionLabel)s" to "%(value)s"`,
        ActionDueByChanged: ({ dueBy }) => {
          const formattedDueBy = moment(dueBy).format('D MMM YYYY');
          return `changed due by date to "${formattedDueBy}"`;
        },
        ActionNoteAdded: `added note "%(note)s"`,
        ActionAssignedToChanged: `changed Assigned To to "%(assignedToName)s"`,
        ActionVerificationStatusChanged: ({ status }) => {
          if (status === 'Verified') return `verified %(locale.label.action)s`;
          if (status === 'Returned') return `returned %(locale.label.action)s`;
          if (status === 'Recheck')
            return `requested this %(locale.label.action)s to be re-checked`;

          if (status === 'Unverified')
            return `set this %(locale.label.action)s to unverified`;

          return `set this %(locale.label.action)s to %(status)s`;
        },
        ActionVerificationNotesChanged: (e) =>
          e.notes
            ? `changed verification notes to "%(notes)s"`
            : `cleared the verification notes`,
        AggregateVerificationEntries: ({ status }) => {
          if (status === 'Verified')
            return `verified %(locale.label.action)s, with verification notes "%(notes)s"`;

          if (status === 'Returned')
            return `returned %(locale.label.action)s, with verification notes "%(notes)s"`;

          if (status === 'Recheck')
            return `requested this %(locale.label.action)s to be re-checked, with verification notes "%(notes)s"`;

          if (status === 'Unverified')
            return `set this %(locale.label.action)s to unverified, with verification notes "%(notes)s"`;

          return `set this %(locale.label.action)s to %(status)s, with verification notes "%(notes)s"`;
        },
        AggregateStatusEntries: `%(status)s %(locale.label.action)s with note "%(note)s"`,
      },
      instance: {
        InstanceArchived: 'Archived',
        InstanceAssignedToChanged: (args) =>
          args.assignedToName !== undefined
            ? `%(locale.label.formAssignedTo)s changed to '%(assignedToName)s'`
            : `%(locale.label.formAssignedTo)s changed`,
        InstanceCancelled: 'Cancelled',
        InstanceCompleted: 'Completed',
        InstanceCreated: 'Created',
        InstanceDueByChanged: `'Due by' changed to '%(dueBy)s'`,
        InstanceInProgress: 'Started',
        InstanceReverted: 'Re-opened',
        InstancePending: 'Pending',
        InstanceSignedOff: `Signed-off for '%(forMemberName)s'`,
        InstanceSignOffReverted: (args) => {
          if (!args.forMemberName) return 'Sign-off reverted';

          return args.reason !== undefined
            ? `Sign-off reverted for '%(forMemberName)s' with reason '%(reason)s'`
            : `Sign-off reverted for '%(forMemberName)s'`;
        },
        InstanceSubjectChanged: (args) =>
          args.subjectName !== undefined
            ? `%(locale.label.formSubject)s changed to '%(subjectName)s'`
            : `%(locale.label.formSubject)s changed`,
        InstanceDocumentAdded: `Added document '%(documentName)s'`,
        InstanceDocumentRemoved: `Removed document '%(documentName)s'`,
        InstanceAppealed: `Appealed: '%(appealReason)s'`,
        InstanceAppealResponseSet: (args) => getAppealResponseAuditLog(args),
        InstanceScoreUpdated: `Form score calculated as '%(score)s' (%(outcome)s)`,
        FormUpdated: 'Updated',
        // Form Answers.
        AnswerArchived: 'Answer archived',
        AnswerCreated: `%(actionedAtTime)s - %(actionedByName)s changed '%(key)s' to '%(computedValue)s'`,
        AnswerValueChanged: (args) =>
          args.computedValue !== undefined
            ? `%(actionedAtTime)s - %(actionedByName)s changed '%(key)s' to '%(computedValue)s'`
            : `%(actionedAtTime)s - %(actionedByName)s changed '%(key)s'`,
        // Answer Issues
        AnswerIssueCreated: `%(actionedAtTime)s - %(actionedByName)s created an issue for '%(questionKey)s'`,
        AnswerIssueClassificationsChanged: `%(actionedAtTime)s - %(actionedByName)s changed details for issue '%(questionKey)s' to '%(classifications)s'`,
        AnswerIssueNotesChanged: `%(actionedAtTime)s - %(actionedByName)s changed notes for issue '%(questionKey)s' to '%(notes)s'`,
        AnswerIssueArchived: `%(actionedAtTime)s - %(actionedByName)s removed an issue for '%(questionKey)s'`,
        AnswerIssueCoachingConversationUpdated: (args) =>
          args.coachingConversation
            ? `%(actionedAtTime)s - %(actionedByName)s changed 'Coaching conversation summary' to '%(coachingConversation)s'`
            : `%(actionedAtTime)s - %(actionedByName)s changed 'Coaching conversation summary'`,
        AnswerIssueFutureCoachingUpdated: (args) => {
          const issueActionLabel = str.plural(
            args.locale.label.issueAction.toLowerCase(),
          );
          return args.actionsRequired === 'True'
            ? `%(actionedAtTime)s - %(actionedByName)s changed 'Are additional ${issueActionLabel} required to prevent this from occurring again?' to 'Yes'`
            : `%(actionedAtTime)s - %(actionedByName)s changed 'Are additional ${issueActionLabel} required to prevent this from occurring again?' to 'No' with reason '${
                args.noActionsRequiredReason ?? ''
              }'`;
        },
        AnswerIssueCauseUpserted: (args) => {
          return args.isPrimary === 'True'
            ? `%(actionedAtTime)s - %(actionedByName)s set '%(issueCauseLabel)s' as the primary cause with details '%(notes)s'`
            : `%(actionedAtTime)s - %(actionedByName)s set '%(issueCauseLabel)s' as a secondary cause with details '%(notes)s'`;
        },
        AnswerIssueCauseDeleted: `%(actionedAtTime)s - %(actionedByName)s removed '%(issueCauseLabel)s' as a cause`,
      },
    },
  },
  forms: {
    bulkUpload: {
      ACTION_DUE_DATE_DEFAULT:
        'No action due date entered. Default duration will be used.',
      ACTION_DUE_DATE_IGNORED:
        'Action due date with no description. Action will not be created.',
      ACTION_DUE_DATE_INVALID: `''%(dueDate)s'' is not a valid action due date.`,
      ACTION_TEAM_NOT_SUPPORTED:
        'Action not supported for a team. Action will not be created.',
      ANSWER_EMPTY: `Empty value given for ''%(questionId)s''.`,
      ANSWER_INVALID: `''%(invalidAnswers)s'' is not an allowed value for ''%(questionId)s''.`,
      ANSWER_ISSUE_CLASSIFICATION_REQUIRED: `At least one classification is required for ''%(questionId)s''.`,
      ANSWER_ISSUE_CLASSIFICATION_IGNORED: `The field ''Classification'' for ''%(questionId)s'' is not visible and will be skipped.`,
      ANSWER_ISSUE_CLASSIFICATION_INVALID: `''%(classification)s'' is not an allowed classification for ''%(questionId)s''.`,
      ANSWER_ISSUE_NOTES_IGNORED: `The field ''Notes'' for ''%(questionId)s'' is not visible and will be skipped.`,
      ANSWER_ISSUE_NOTES_REQUIRED: `Notes are required for ''%(questionId)s''.`,
      ANSWER_QUESTION_HIDDEN: `The field ''%(questionId)s'' is not visible and will be skipped.`,
      ANSWER_QUESTION_NOT_FOUND: `''%(questionId)s'' could not be found.`,
      ANSWER_QUESTION_NOT_SUPPORT: `''%(questionId)s'' is not a supported question type. e.g. dropDownName, number slider.`,
      ANSWER_REQUIRED: `The answer ''%(questionId)s'' is required.`,
      AUTOMATED_ACTIONS_ASSIGNED_TO_NOT_FOUND: (args) => {
        const automatedActionIdCount =
          args.automatedActionIds?.split(',').length ?? 0;

        return automatedActionIdCount < 1
          ? ''
          : `${automatedActionIdCount} ${str.plural('Smart Action', automatedActionIdCount)} ${automatedActionIdCount == 1 ? 'has' : 'have'} no Assigned To so will not be created.`;
      },
      AUTOMATED_ACTIONS_WITH_PLACEHOLDER_TEXT: (args) => {
        const automatedActionIdCount =
          args.automatedActionIds?.split(',').length ?? 0;

        return automatedActionIdCount < 1
          ? ''
          : `${automatedActionIdCount} ${str.plural('Smart Action', automatedActionIdCount)} ${automatedActionIdCount == 1 ? 'has' : 'have'} placeholder text so will not be created.`;
      },
      DUPLICATED_CURRENT_IMPORT: 'Row is duplicated in current upload.',
      DUPLICATED_PREVIOUS_IMPORT:
        'Row has duplicate information to a row previously uploaded.',
      ERROR_OUTCOME: 'Row will not be imported.',
      FILE_BAD_STRUCTURE: 'File has an unsupported structure.',
      FILE_BAD_TYPE: 'Invalid file type imported.',
      FILE_EMPTY: 'File is empty.',
      FORM_ASSIGNED_TO_EMPTY: `%(locale.label.formAssignedTo)s is blank, not able to automatically sign off.`,
      FORM_ASSIGNED_TO_MULTIPLE_FOUND: `Multiple matches found for %(locale.label.formAssignedTo)s (%(searchValue)s).`,
      FORM_ASSIGNED_TO_NOT_FOUND: `No match for %(locale.label.formAssignedTo)s (%(searchValue)s).`,
      FORM_SUBJECT_EMPTY: '%(locale.label.formSubject)s is blank.',
      FORM_SUBJECT_MULTIPLE_FOUND:
        'Multiple matches found for %(locale.label.formSubject)s (%(searchValue)s).',
      FORM_SUBJECT_NOT_FOUND:
        'No match for %(locale.label.formSubject)s (%(searchValue)s).',
      FORM_TEMPLATE_BAD_IDENTIFIER: `No match for ''%(templateId)s'' template.`,
      FORM_TEMPLATE_INVALID: `''%(templateId)s'' template is invalid.`,
      FORM_TEMPLATE_MULTIPLE_FOUND: `Multiple matches found for ''%(templateId)s'' template.`,
      FORM_TEMPLATE_NOT_FOUND: `No match for ''%(searchValue)s'' template.`,
      WARNING_OUTCOME:
        'Row will be imported but noted field(s) will be ignored.',
    },
    closeTheLoop: {
      title: 'QA Coaching & Training Actions',
      issueCheckInGuidanceScript: `
  Below are all items from QA reviews this period that have required coaching.
          
  Review open coaching actions closing the loop on QA issues. Complete or update due dates as required.`,
      issueCoachingPlanGuidanceScript: `
  This coaching session has been generated because a coachable issue was identified in a recent QA review. These provide you with the opportunity to develop your skills and knowledge.
        
  This session will capture the coaching conversation with your manager today and any coaching actions created to support your ongoing capability.
        
  - Work through these together with your manager.
  - Identify the primary cause and outline why.
  - Capture details of the coaching conversation.
  - Add any coaching actions using the SMART methodology - these will be available to review in future coaching sessions.
  - All Coaching Required items need to be reviewed before this session can be signed off.`,
      issueCoachingPlanCoachingRequiredGuidanceScript: `
  These items have more impact on customer experience and business outcomes and should be reviewed individually.

  For each item:
  - Identify the primary cause.
  - Capture today's coaching conversation.
  - Agree on any coaching actions.`,
      issueCoachingPlanCoachingRecommendedGuidanceScript: `
  These items may have an impact on customer experience and business outcomes and can be reviewed individually.

  For each item:
  - Identify the primary cause.
  - Capture today's coaching conversation.
  - Agree on any coaching actions.`,
      issueCoachingPlanAdditionalActionsGuidanceText:
        'Using the SMART methodology, outline planned coaching actions below. These will be available to review in future coaching sessions.',
    },
    instance: {
      confirmCancellation: 'Are you sure? This action cannot be undone.',
    },
  },
};

const DEFAULT_LOCALE_OVERRIDES_BY_MODULE: {
  [key in ModuleType]: DeepPartial<TenantLocale>;
} = {
  coaching: {
    label: {
      actionNote: 'Notes',
      issueAction: 'Coaching Action',
      form: 'Session',
      formAssignedTo: 'Coach',
      formSubject: 'Coachee',
    },
    error: {
      formLoadDefault:
        'There was a problem loading your session. Please contact your support team.',
      formLoadRefresh:
        'There was a problem loading your session. Click here to retry.',
      formCreateRetry:
        'There was a problem creating your session. Click here to retry.',
    },
  },
  compliance: {
    label: {
      actionNote: 'Resolution Notes',
      issueAction: 'Action',
      form: 'Review',
      formAssignedTo: 'Reviewer',
      formSubject: 'Under Test',
    },
    error: {
      formLoadDefault:
        'There was a problem loading your review. Please contact your support team.',
      formLoadRefresh:
        'There was a problem loading your review. Click here to retry.',
      formCreateRetry:
        'There was a problem creating your review. Click here to retry.',
    },
  },
  cadence: {
    label: {
      actionNote: 'Notes',
      form: 'Meeting',
      formAssignedTo: 'Facilitator',
      formSubject: 'Team',
    },
    error: {
      formLoadDefault:
        'There was a problem loading your meeting. Please contact your support team.',
      formLoadRefresh:
        'There was a problem loading your meeting. Click here to retry.',
      formCreateRetry:
        'There was a problem creating your meeting. Click here to retry.',
    },
  },
};

export function getLocale(
  configuration?: TenantConfiguration,
  module?: ModuleType,
): TenantLocale {
  const baseLocale = configuration?.locale;
  const moduleLocale = configuration?.modules?.[module]?.localeOverrides ?? {};

  return mergeDeepRightAll(
    DEFAULT_LOCALE,
    baseLocale,
    DEFAULT_LOCALE_OVERRIDES_BY_MODULE[module],
    moduleLocale,
  );
}

const DEFAULT_TENANT_CONFIG: Partial<TenantConfiguration> = {
  appeal: {
    outcomeReasons: [
      'Missing information',
      'No initial error',
      'Not in policy',
      'Other',
    ],
    reasonCategories: [
      'Information had been provided',
      'Reviewer error',
      'Not in policy',
      'Other',
    ],
  },
  timezone: momentTz.tz.guess(),
};

export function getTenantConfigWithDefaults(
  configuration?: TenantConfiguration,
): TenantConfiguration {
  return mergeDeepRightAll<TenantConfiguration>(
    DEFAULT_TENANT_CONFIG,
    configuration,
  );
}

export function getTenantFeatures(
  configuration?: TenantConfiguration,
  module?: ModuleType,
): TenantFeatures {
  if (!configuration) return DEFAULT_FEATURES;

  const baseFeatures = configuration.features;
  const moduleFeatures = configuration.modules[module]?.featuresOverrides ?? {};

  const features = mergeDeepRightAll(
    DEFAULT_FEATURES,
    baseFeatures,
    moduleFeatures,
  );

  const overrideFeatures = getTempOverrideTenantFeatures(features);
  return overrideFeatures
    ? mergeDeepRightAll(features, overrideFeatures)
    : features;
}

const getTempOverrideTenantFeatures = (
  features: TenantFeatures,
): DeepPartial<TenantFeatures> => {
  if (typeof window === 'undefined') return {};

  return {
    DASHBOARDS: {
      TEMP_FILTERS:
        storageApi.temp.gridFilters() ?? features.DASHBOARDS.TEMP_FILTERS,
    },
  };
};

const getAppealResponseAuditLog = (args: any) => {
  if (!args.appealOutcome)
    return `#### Responded to appeal: '%(appealResponse)s'`;

  if (args.appealOutcome === 'Withdrawn' && !args.appealResponse)
    return '#### Appeal withdrawn';

  return `#### Responded to appeal
- Response: '%(appealResponse)s'
- Reason: '%(appealCategory)s'
- Outcome: '%(appealOutcome)s'
${
  args.appealOutcomeReason ? `- Outcome reason: '%(appealOutcomeReason)s'` : ''
}`;
};
