import { formsQuery } from '@se/data/forms/query/index.ts';
import { FormTemplate } from '@se/data/forms/types.ts';
import { getFormTemplateSubjectType } from '@se/data/forms/utils.ts';
import { DROPDOWN_RESERVED_IDS } from '@seeeverything/ui.forms/src/util/constants.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { concatAll, EMPTY, filter, map, Observable, switchMap } from 'rxjs';
import sanitize from 'sanitize-filename';
import { utils, writeFile } from 'xlsx';
import { FormTemplateItemDefinition } from '../../parse/types/template.types.ts';
import { ANSWER_TYPE_LABELS } from '../../util/constants.ts';
import { exportableQuestionsFromTemplate } from '../../util/util.parse.template.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { ExportableQuestion } from './formBulkUploadDownloadTemplateSlice.ts';
import { formBulkUploadDownloadTemplateSlice } from './index.ts';

export const formBulkUploadDownloadTemplateEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  templateSelectedEpic,
  toggleShowHiddenFieldsEpic,
  templateQuestionsLoadEpic,
  templateExportEpic,
);

function templateSelectedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formBulkUploadDownloadTemplateSlice.selectTemplate.match),
    map(() => formBulkUploadDownloadTemplateSlice.templateQuestionsLoad()),
  );
}

function toggleShowHiddenFieldsEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formBulkUploadDownloadTemplateSlice.toggleShowHiddenFields.match),
    map(() => {
      return formBulkUploadDownloadTemplateSlice.templateQuestionsLoad();
    }),
  );
}

function templateQuestionsLoadEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formBulkUploadDownloadTemplateSlice.templateQuestionsLoad.match),
    filter(() =>
      Boolean(state$.value.formBulkUploadDownloadTemplate.selectedTemplate),
    ),
    switchMap(async () => {
      const templateResponse = await formsQuery.getFormTemplate(
        client,
        state$.value.formBulkUploadDownloadTemplate.selectedTemplate.id,
      );

      if (!templateResponse.isSuccess)
        return formBulkUploadDownloadTemplateSlice.templateQuestionsLoadError();

      const definition = templateResponse.data.currentDefinition?.definition;
      if (!definition)
        return formBulkUploadDownloadTemplateSlice.templateQuestionsLoadError();

      const definitionParsed = JSON.parse(
        definition,
      ) as FormTemplateItemDefinition[];

      const templateExportableQuestions = exportableQuestionsFromTemplate(
        definitionParsed,
        state$.value.formBulkUploadDownloadTemplate.showHiddenFields,
        state$.value.formBulkUploadDownloadTemplate.exportableQuestions,
      );

      const exportableQuestions =
        getFormTemplateSubjectType(definition) !== 'Team'
          ? templateExportableQuestions.concat(ACTION_EXPORT_OPTIONS)
          : templateExportableQuestions;

      return formBulkUploadDownloadTemplateSlice.templateQuestionsLoaded({
        exportableQuestions,
      });
    }),
  );
}

function templateExportEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(formBulkUploadDownloadTemplateSlice.exportTemplate.match),
    map((action) => {
      const { selectedQuestions, selectedTemplate } = action.payload;
      const formLabel = state$.value.tenantState.tenant.locale.label.form;
      const formAssignedToLabel =
        state$.value.tenantState.tenant.locale.label.formAssignedTo;

      const workbook = utils.book_new();
      workbook.Props = {
        Title: `${selectedTemplate.name} Template Export`,
        Subject: 'Template Export',
        Author: 'SeeEverything',
      };

      const WORKSHEET_NAME_CHAR_LIMIT = 31;

      const templateWorksheetName = selectedTemplate.name.substring(
        0,
        WORKSHEET_NAME_CHAR_LIMIT,
      );
      workbook.SheetNames.push(templateWorksheetName);
      workbook.Sheets[templateWorksheetName] = utils.aoa_to_sheet(
        generateImportWorksheetData(
          selectedQuestions,
          selectedTemplate,
          formLabel,
        ),
      );

      workbook.SheetNames.push('Guidance');
      workbook.Sheets['Guidance'] = utils.aoa_to_sheet(
        generateGuidanceWorksheetData(
          selectedQuestions,
          selectedTemplate,
          formLabel,
          formAssignedToLabel,
        ),
      );

      writeFile(
        workbook,
        `export-${toFileName(selectedTemplate.category.name)}-${toFileName(
          selectedTemplate.name,
        )}.xlsx`,
      );

      return EMPTY;
    }),
    concatAll(),
  );
}

/**
 * Generates worksheet data in from an array of arrays where each array is a row and
 * every string in the array is a column e.g.
 * [
 *    [col1, col2, col3],
 *    [col1, col2, col3],
 *    [col1, col2, col3],
 * ]
 */
const generateImportWorksheetData = (
  selectedQuestions: ExportableQuestion[],
  template: FormTemplate,
  formLabel: string,
) => {
  const exportableKeys = selectedQuestions
    .filter((key) => !key.isHeading)
    .map(getDisplayKey);

  return [
    ['Category', formLabel, ...exportableKeys],
    [template.category.name, template.name],
  ];
};

const generateGuidanceWorksheetData = (
  selectedExportQuestions: ExportableQuestion[],
  template: FormTemplate,
  formLabel: string,
  formAssignedToLabel: string,
) => {
  const keyAndOptionsRow = selectedExportQuestions
    .filter((key) => !key.isHeading)
    .map((key) => [getDisplayKey(key), ...key.answerOptions]);

  const templateFields = [
    ['Category', template.category.name],
    [formLabel, template.name],
  ];

  const guidanceGrid = transposeData([...templateFields, ...keyAndOptionsRow]);

  const guidanceText =
    'Data in this tab will not be imported. The below is provided as a guide for filling in the first tab.';
  const guidanceText2 = `<Category> and <${formLabel}> need to be repeated for every row. ${formAssignedToLabel} is optional. 
    <Multiselect> are | separated, eg Savings|Loan. 
    <Date> accepts format d-mmm-yy, eg 1-May-21. 
    <Number> contains no decimal places.`;

  const includeAction = selectedExportQuestions.some(
    (key) => key.id === 'Action',
  );

  const guidanceText3 = includeAction
    ? [
        `To create an action against the ${formLabel.toLowerCase()}, add an Action Description and optionally an Action Due Date. If no due date entered, the default will be used.`,
      ]
    : undefined;

  return [
    [guidanceText],
    [guidanceText2],
    guidanceText3,
    [''],
    ...guidanceGrid,
  ].filter(Boolean);
};

const getDisplayKey = (key: ExportableQuestion) =>
  DROPDOWN_RESERVED_IDS.includes(key.id) ? key.label : key.id;

const transposeData = (grid: string[][]) =>
  grid
    .reduce(
      (longestRow, currentRow) =>
        longestRow.length > currentRow.length ? longestRow : currentRow,
      [],
    )
    .map((_, colIndex) => grid.map((row) => row[colIndex]));

/**
 * Sanitize a string for a file name, convert to lower case and replace spaces with a "-".
 */
const toFileName = (input = ''): string =>
  sanitize(input.replace(/\s+/g, '-').toLowerCase());

const ACTION_EXPORT_OPTIONS = [
  {
    id: 'Action',
    label: 'Action',
    isSelected: true,
    isHeading: true,
    isHiddenByDefault: false,
  },
  {
    id: 'Action Description',
    label: 'Action Description',
    isSelected: true,
    isHiddenByDefault: false,
    parentHeadingId: 'Action',
    answerOptions: [ANSWER_TYPE_LABELS.text],
  },
  {
    id: 'Action Due Date',
    label: 'Action Due Date',
    isSelected: true,
    isHiddenByDefault: false,
    parentHeadingId: 'Action',
    answerOptions: [ANSWER_TYPE_LABELS.date],
  },
];
