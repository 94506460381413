/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import moment from 'moment';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { isActiveInFormLifespan } from '../util.ts';
import { AddGoal } from './AddGoal.tsx';
import { GoalContainer } from './GoalContainer.tsx';
import { StandaloneActionsContainer } from './StandaloneActionsContainer.tsx';

export interface IGoalsContainerProps {
  canCreateInInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  typeFilter?: 'ONLY_NEW' | 'ONLY_EXISTING';
}

export const GoalsContainer: React.FC<IGoalsContainerProps> = ({
  canCreateInInstance,
  instanceId,
  isPdfExport,
  typeFilter,
}) => {
  const allGoals = useFormsSelector((state) => state.formActionPlanV2.goals);

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject?.name,
  );

  const instanceCreatedAt = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.createdAt,
  );

  const instanceSignOffDate = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return undefined;

    return instance.signOffs?.find(
      (signOff) => signOff.id === instance.reviewer?.id,
    )?.occurredAt;
  });

  const filteredGoals = useMemo(() => {
    const goals = Object.values(allGoals);
    if (!goals.length) return [];

    return goals.filter((goal) => {
      const includeForOnlyExisting = Boolean(
        goal.formInstance?.id !== instanceId &&
          moment(goal.createdAt) < moment(instanceCreatedAt),
      );
      if (typeFilter === 'ONLY_EXISTING' && !includeForOnlyExisting)
        return false;

      const includeForOnlyNew = Boolean(
        goal.formInstance?.id === instanceId &&
          moment(goal.createdAt) > moment(instanceCreatedAt),
      );
      if (typeFilter === 'ONLY_NEW' && !includeForOnlyNew) return false;

      return isActiveInFormLifespan(
        goal,
        instanceCreatedAt,
        instanceSignOffDate,
      );
    });
  }, [
    allGoals,
    instanceCreatedAt,
    instanceId,
    instanceSignOffDate,
    typeFilter,
  ]);

  const actions = useFormsSelector((state) =>
    Object.values(state.formActionPlanV2.actions),
  );

  const actionLabel = useTenantLocale((l) => `goals or ${l.label.action}`);

  const standaloneActionsEnabled = useFormsSelector(
    (state) =>
      state.tenantState.tenant.features.FORMS.CAN_CREATE_STANDALONE_ACTIONS,
  );

  const standaloneActions = useMemo(
    () =>
      actions.filter((action) => {
        if (action.goalId) return false;
        return action.formInstance?.id === instanceId;
      }),
    [actions, instanceId],
  );

  const elEmpty = standaloneActions.length === 0 &&
    filteredGoals.length === 0 &&
    !canCreateInInstance && (
      <Text color={color.format(-0.5)} italic={true}>
        {`No existing ${str.plural(actionLabel)}.`}
      </Text>
    );

  return (
    <div css={styles.base}>
      {filteredGoals.map((goal) => (
        <GoalContainer
          id={goal.id}
          instanceId={instanceId}
          key={`Goal-${goal.id}`}
          source={{ type: 'FORM', instanceId }}
          isPdfExport={isPdfExport}
        />
      ))}
      {canCreateInInstance && (
        <AddGoal
          message={
            filteredGoals.length
              ? 'Add another goal.'
              : `Add a new goal for ${instanceSubject}.`
          }
          instanceId={instanceId}
        />
      )}
      <StandaloneActionsContainer
        instanceId={instanceId}
        isDefaultExpanded={true}
        actions={standaloneActions}
        canCreateActions={canCreateInInstance}
        typeFilter={typeFilter}
        standaloneActionsEnabled={standaloneActionsEnabled}
      />
      {elEmpty}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
};
