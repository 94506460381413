import {
  DropdownAnswerDefinition,
  InputDefinition,
  InputFieldsDefinition,
} from '@seeeverything/ui.forms/src/parse/types/input.types.ts';
import { TextSectionDefinition } from '@seeeverything/ui.forms/src/parse/types/section.types.ts';
import {
  FormTemplateItemDefinition,
  SectionItem,
} from '@seeeverything/ui.forms/src/parse/types/template.types.ts';

export const getFormTemplateSubjectType = (definition: string) => {
  let items: FormTemplateItemDefinition[] = [];
  try {
    items = JSON.parse(definition);
  } catch {
    return undefined;
  }

  const sections = items
    .map((item) => (item as SectionItem).section)
    .filter(Boolean);

  const questions = sections
    .map((section) => section.questions)
    .filter(Boolean)
    .flat();

  const textQuestions = questions
    .map((question) => (question as TextSectionDefinition).text)
    .filter(Boolean);

  const inputs = textQuestions
    .map(
      (textQuestion) =>
        (textQuestion as InputFieldsDefinition).fields ?? [
          textQuestion as InputDefinition,
        ],
    )
    .flat();

  const subjectQuestion = inputs
    .map((input: DropdownAnswerDefinition) =>
      input.dropdownListName && input.id === 'subject'
        ? input.dropdownListName
        : undefined,
    )
    .filter(Boolean)?.[0]
    ?.toLowerCase();

  return subjectQuestion === 'people' ? 'Person' : 'Team';
};
