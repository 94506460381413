import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { FormBulkUploadJob } from '../types.ts';

type GetFormsBulkUploadJobHistoryArgs = {
  pageNumber: number;
  orderBys: OrderBy[];
};

export type GetFormsBulkUploadJobHistoryResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  jobs: FormBulkUploadJob[];
};

export const getFormsBulkUploadJobHistory = async (
  client: IGraphQLClient,
  args: GetFormsBulkUploadJobHistoryArgs,
): Promise<QueryResult<GetFormsBulkUploadJobHistoryResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formsBulkUploadJobHistory: {
          pageInfo: { hasNextPage: boolean; currentPage: number };
          nodes: Array<{
            id: string;
            jobStatus: 'Processing' | 'Partial Success' | 'Success';
            failed: number;
            succeeded: number;
            templateNames: string;
            uploadedAt: string;
            uploadedBy: {
              id: string;
              name: string;
            };
          }>;
        };
      };
    }>({
      query: gql`
        query FormsBulkUploadJobHistory(
          $pageNumber: Int!
          $orderBys: [OrderByInput!]
        ) {
          forms {
            formsBulkUploadJobHistory(
              jobType: Create
              orderBy: $orderBys
              pagination: { size: 100, pageNumber: $pageNumber }
            ) {
              pageInfo {
                currentPage
                hasNextPage
              }
              nodes {
                id
                failed
                jobStatus
                succeeded
                templateNames
                uploadedAt
                uploadedBy {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        pageNumber: args.pageNumber,
        orderBys: args.orderBys,
      },
      fetchPolicy: 'network-only',
    });

    const { pageInfo, nodes } = response.data.forms.formsBulkUploadJobHistory;

    if (!nodes) {
      log.error('Failed to retrieve Bulk Upload jobs');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        jobs: nodes.map(
          (node): FormBulkUploadJob => ({
            id: node.id,
            jobStatus: node.jobStatus,
            failed: node.failed,
            succeeded: node.succeeded,
            templateNames: node.templateNames,
            uploadedAt: node.uploadedAt,
            uploadedBy: {
              id: node.uploadedBy.id,
              name: node.uploadedBy.name,
            },
          }),
        ),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query Bulk Upload jobs - ${error.message}`,
      error,
    );
    return { isSuccess: false, errorReason: 'UNKNOWN', error };
  }
};
