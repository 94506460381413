/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { useCallback } from 'react';
import { issueSlice } from '../../redux/issue/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormListItem } from '../FormListItem/FormListItem.tsx';
import { IssueContainer } from '../Issue/IssueContainer.tsx';
import { IssueGroupSkeleton } from '../Issue/IssueGroupSkeleton.tsx';
import { Empty } from '../IssueCoachingPlan/components/Empty.tsx';
import { SectionTitle } from '../SectionTitle/SectionTitle.tsx';
import { SpeechBlockContainer } from '../SpeechBlock/SpeechBlockContainer.tsx';

export interface IIssueCheckInContainerProps {
  instanceId: string;
  isPdfExport: boolean;
}

export const IssueCheckInContainer: React.FC<IIssueCheckInContainerProps> = ({
  instanceId,
  isPdfExport,
}) => {
  const dispatch = useFormsDispatch();
  const issues = useFormsSelector((state) => state.formIssue.issues);
  const isLoading = useFormsSelector((state) => state.formIssue.isLoading);

  const guidanceText = useFormsSelector(
    (state) =>
      state.tenantState.tenant?.locale.forms.closeTheLoop
        .issueCheckInGuidanceScript,
  );

  const emptyReason = useFormsSelector((state) => {
    if (state.formIssue.loadError) return 'LOAD_ERROR';
    if (!state.formInstance.instances?.[instanceId]?.subject)
      return 'SUBJECT_UNASSIGNED';
    if (!state.formIssue.canView) return 'INSUFFICIENT_PERMISSIONS';
    return 'NO_ISSUES_COACHING_CHECK_IN';
  });

  const sectionTitle = useFormsSelector(
    (state) => state.tenantState.tenant?.locale.forms.closeTheLoop.title,
  );

  const reloadAnswerIssues = useCallback(() => {
    setTimeout(() => {
      dispatch(issueSlice.loadIssues());
      dispatch(issueSlice.loadIssueCoachingPlan({ instanceId }));
    }, 225);
  }, [dispatch, instanceId]);

  const elLoading = isLoading && <IssueGroupSkeleton />;

  const isEmpty = !issues.length;
  const elContent = isEmpty ? (
    <Empty
      reason={emptyReason}
      coacheeLabel={'Coachee'}
      onReload={reloadAnswerIssues}
    />
  ) : (
    <>
      <SpeechBlockContainer
        id={`issueCheckIn-continuousImprovementGuidance`}
        instanceId={instanceId}
        isPdfExport={isPdfExport}
        markdown={guidanceText}
      />
    </>
  );

  return (
    <FormListItem
      id={'issueCheckIn'}
      key={'issueCheckIn'}
      bullet={'none'}
      marginTop={60}
      marginBottom={40}
    >
      <div css={styles.base}>
        <FormListItem
          id={'issueCheckIn-continuousImprovement'}
          bullet={isPdfExport ? 'none' : 'block'}
          paddingLeft={-32}
          paddingRight={-32}
          marginBottom={isEmpty && !isLoading ? 20 : 0}
          bulletTop={-12}
          bulletLeft={-32}
          style={styles.continuousImprovement}
        >
          <SectionTitle text={sectionTitle} />
        </FormListItem>
        {!isLoading && elContent}
        <div css={styles.issues}>
          {issues.map((issue) => (
            <div
              key={`issueCheckInContainer-${issue.id}`}
              css={styles.issueContainer}
            >
              <IssueContainer
                id={issue.id}
                enableActions={true}
                showReviewLink={true}
                instanceId={instanceId}
                showCreatedActions={false}
              />
            </div>
          ))}
        </div>
        {elLoading}
      </div>
    </FormListItem>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    flex: '1 1 auto',
  }),
  issues: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }),
  issueContainer: css({
    ...CommonStyles.BoxShadowGroup,
    padding: 12,
  }),
  continuousImprovement: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  }),
};
