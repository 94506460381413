/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { FormGoalCategory } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { isActiveInFormLifespan } from '../util.ts';
import { GoalCategoryContainer } from './GoalCategoryContainer.tsx';
import { IssueActionsContainer } from './IssueActionsContainer.tsx';
import { SkeletonActionPlan } from './SkeletonActionPlan.tsx';
import { StandaloneActionsContainer } from './StandaloneActionsContainer.tsx';

export interface IGoalCategoriesContainerProps {
  canCreateInInstance: boolean;
  includeCategories: string[];
  instanceId: string;
  isPdfExport: boolean;
  typeFilter?: 'ONLY_NEW' | 'ONLY_EXISTING';
}

export const GoalCategoriesContainer: React.FC<
  IGoalCategoriesContainerProps
> = ({
  canCreateInInstance,
  includeCategories,
  instanceId,
  isPdfExport,
  typeFilter,
}) => {
  const { client } = useGraphQL();

  const {
    data: formGoalCategoriesData,
    isLoading: isLoadingFormGoalCategories,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [{ key: 'goalCategories' }],
    queryFn: async () => {
      const response = await formsQuery.getFormGoalCategories(client, {
        fetchAllPages: true,
      });

      return response.isSuccess ? response.data : null;
    },
  });

  const goalCategories = useMemo(
    () => formGoalCategoriesData?.formGoalCategories ?? [],
    [formGoalCategoriesData?.formGoalCategories],
  );

  const instanceCreatedAt = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.createdAt,
  );

  const instanceSignOffDate = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return undefined;

    return instance.signOffs?.find(
      (signOff) => signOff.id === instance.reviewer?.id,
    )?.occurredAt;
  });

  const instanceIssueIds = useFormsSelector((state) =>
    state.formIssue.issues.map((issue) => issue.id),
  );

  const goals = useFormsSelector((state) =>
    Object.values(state.formActionPlanV2.goals),
  );

  const archivedGoalCategories = useMemo(
    () =>
      goals.reduce((acc, goal) => {
        if (!goal.goalCategory) return acc;

        const isActive = goalCategories.some(
          (category) => category.id === goal.goalCategory.id,
        );
        if (isActive) return acc;

        if (acc.some((category) => category.id === goal.goalCategory.id))
          return acc;

        acc.push({
          id: goal.goalCategory.id,
          name: goal.goalCategory.name,
        });
        return acc;
      }, new Array<FormGoalCategory>()),
    [goalCategories, goals],
  );

  const allGoalCategories = useMemo(
    () => (goalCategories ?? []).concat(archivedGoalCategories),
    [archivedGoalCategories, goalCategories],
  );

  const hasGoalCategories = useFormsSelector(
    (state) => state.tenantState.tenant.features.FORMS.GOAL_CATEGORIES,
  );

  const goalsEnabled = useFormsSelector(
    (state) => state.tenantState.tenant.features.FORMS.GOALS,
  );

  const categoryGroups = useMemo(() => {
    if (!hasGoalCategories) return [];
    if (!goalsEnabled) return [];

    return allGoalCategories
      .map((category) => {
        if (
          includeCategories.length > 0 &&
          !includeCategories.includes(category.name)
        )
          return;

        const isCategoryArchived = archivedGoalCategories.some(
          (a) => a.id === category.id,
        );

        const canCreateGoals =
          canCreateInInstance &&
          !isCategoryArchived &&
          typeFilter !== 'ONLY_EXISTING';

        let filteredGoals = goals.filter((goal) => {
          if (goal.goalCategory?.id !== category.id) return false;
          return isActiveInFormLifespan(
            goal,
            instanceCreatedAt,
            instanceSignOffDate,
          );
        });

        if (typeFilter === 'ONLY_EXISTING') {
          filteredGoals = filteredGoals.filter((goal) => {
            if (goal.formInstance?.id === instanceId) return false;
            return moment(goal.createdAt) < moment(instanceCreatedAt);
          });
        }

        if (typeFilter === 'ONLY_NEW') {
          filteredGoals = filteredGoals.filter((goal) => {
            if (goal.formInstance?.id !== instanceId) return false;
            return moment(goal.createdAt) > moment(instanceCreatedAt);
          });
        }

        if (!filteredGoals.length && !canCreateGoals) return;

        return { category, goals: filteredGoals, canCreateGoals };
      })
      .filter(Boolean);
  }, [
    allGoalCategories,
    archivedGoalCategories,
    canCreateInInstance,
    goals,
    goalsEnabled,
    hasGoalCategories,
    includeCategories,
    instanceCreatedAt,
    instanceId,
    instanceSignOffDate,
    typeFilter,
  ]);

  const standaloneActionsEnabled = useFormsSelector(
    (state) =>
      state.tenantState.tenant.features.FORMS.CAN_CREATE_STANDALONE_ACTIONS,
  );

  const actions = useFormsSelector((state) =>
    Object.values(state.formActionPlanV2.actions),
  );

  const standaloneActions = useMemo(() => {
    return actions.filter((action) => {
      if (action.goalId) return false;
      if (action.issueId) return false;

      if (
        !isActiveInFormLifespan(action, instanceCreatedAt, instanceSignOffDate)
      )
        return false;

      if (typeFilter === 'ONLY_EXISTING') {
        if (action.formInstance?.id === instanceId) return false;
        return true;
      }

      if (typeFilter === 'ONLY_NEW') {
        if (action.formInstance?.id !== instanceId) return false;
        if (moment(action.createdAt) < moment(instanceCreatedAt)) return false;
        return true;
      }

      return true;
    });
  }, [actions, instanceCreatedAt, instanceId, instanceSignOffDate, typeFilter]);

  const showStandaloneActions = useMemo(() => {
    if (!standaloneActionsEnabled) return false;

    if (
      includeCategories.length > 0 &&
      !includeCategories.includes('Coaching Actions')
    )
      return false;

    if (canCreateInInstance && typeFilter !== 'ONLY_EXISTING') return true;
    if (standaloneActions.length) return true;

    return false;
  }, [
    canCreateInInstance,
    includeCategories,
    standaloneActions.length,
    standaloneActionsEnabled,
    typeFilter,
  ]);

  const issueActions = useMemo(
    () =>
      actions.filter((action) => {
        if (!action.issueId) return false;
        if (action.status === 'Completed') return false;
        if (action.status === 'Cancelled') return false;

        if (action.formInstance?.id === instanceId) return false;

        if (typeFilter === 'ONLY_NEW') return false;

        if (
          typeFilter === 'ONLY_EXISTING' &&
          moment(action.createdAt) > moment(instanceCreatedAt)
        )
          return false;

        if (instanceIssueIds.includes(action.issueId)) return false;

        return true;
      }),
    [actions, instanceCreatedAt, instanceId, instanceIssueIds, typeFilter],
  );

  const showIssueActions = useMemo(() => {
    if (
      includeCategories.length > 0 &&
      !includeCategories.includes('Issue Coaching Actions')
    )
      return false;

    if (issueActions.length) return true;

    return false;
  }, [includeCategories, issueActions.length]);

  const isEmpty = useMemo(() => {
    if (categoryGroups.length > 0) return false;
    if (showStandaloneActions) return false;
    if (showIssueActions) return false;

    return true;
  }, [categoryGroups.length, showIssueActions, showStandaloneActions]);

  if (isLoadingFormGoalCategories)
    return (
      <div css={styles.loading}>
        <SkeletonActionPlan />
      </div>
    );

  return (
    <>
      {isEmpty && (
        <Text color={color.format(-0.5)} italic={true}>
          {'No existing goals.'}
        </Text>
      )}
      {showStandaloneActions && (
        <StandaloneActionsContainer
          instanceId={instanceId}
          isDefaultExpanded={isPdfExport || includeCategories.length === 1}
          actions={standaloneActions}
          canCreateActions={canCreateInInstance}
          standaloneActionsEnabled={standaloneActionsEnabled}
          typeFilter={typeFilter}
        />
      )}
      {showIssueActions && (
        <IssueActionsContainer
          instanceId={instanceId}
          isDefaultExpanded={isPdfExport || includeCategories.length === 1}
          actions={issueActions}
        />
      )}
      {categoryGroups.map((categoryGroup) => (
        <GoalCategoryContainer
          id={categoryGroup.category.id}
          canCreateGoals={!isPdfExport && categoryGroup.canCreateGoals}
          goals={categoryGroup.goals}
          instanceId={instanceId}
          isDefaultExpanded={isPdfExport || includeCategories.length === 1}
          isPdfExport={isPdfExport}
          key={`GoalCategory-${categoryGroup.category.id}`}
          name={categoryGroup.category.name}
        />
      ))}
    </>
  );
};

const styles = {
  loading: css({
    ...CommonStyles.BoxShadowGroup,
    padding: 12,
  }),
};
