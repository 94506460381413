import { formsQuery } from '@se/data/forms/query/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, mergeMap, Observable } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formBulkUploadJobHistorySlice from './formBulkUploadJobHistorySlice.ts';

export const formBulkUploadJobHistoryEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(loadJobHistoryEpic);

function loadJobHistoryEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formBulkUploadJobHistorySlice.loadJobs.match),
    mergeMap(async (action) => {
      const jobResponse = await formsQuery.getFormsBulkUploadJobHistory(
        client,
        {
          pageNumber: action.payload.loadNextPage
            ? state$.value.formBulkUploadJobHistory.currentPage + 1
            : 1,
          orderBys: state$.value.formBulkUploadJobHistory.orderBys,
        },
      );

      return jobResponse.isSuccess
        ? formBulkUploadJobHistorySlice.loadedJobs({
            currentPage: jobResponse.data.pageInfo.currentPage,
            hasNextPage: jobResponse.data.pageInfo.hasNextPage,
            jobs: jobResponse.data.jobs,
          })
        : formBulkUploadJobHistorySlice.loadJobsFailed();
    }),
  );
}
