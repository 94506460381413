import { isAnyOf } from '@reduxjs/toolkit';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, map, mergeMap, of } from 'rxjs';
import * as automatedActionSlice from '../automatedAction/automatedActionSlice.ts';
import { ReduxFormInstanceAnswerChange } from '../form-instance/types.ts';
import { formActionSlice } from '../formAction/index.ts';
import { issueSlice } from '../issue/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

export const automatedActionEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  automatedActionCreatedEpic,
  loadAnswerAutomatedActionsForInstanceEpic,
  loadingAutomatedActionOnAnswerChangeEpic,
  loadingAutomatedActionOnClassificationChangeEpic,
  reloadAnswerAutomatedActionsOnRecommendedActionChangedEpic,
);

function loadingAutomatedActionOnAnswerChangeEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    map((action) =>
      automatedActionSlice.loadingAutomatedActionsForAnswer({
        answerId:
          action.payload.change.type === 'CREATE'
            ? action.payload.change.toAnswer.id
            : action.payload.change.fromAnswer.id,
      }),
    ),
  );
}

function loadingAutomatedActionOnClassificationChangeEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        issueSlice.addClassification.match,
        issueSlice.deleteClassification.match,
        issueSlice.saveClassifications.match,
      ),
    ),
    map((action) =>
      automatedActionSlice.loadingAutomatedActionsForAnswer({
        answerId: action.payload.answerId,
      }),
    ),
  );
}

function automatedActionCreatedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    filter((action) => {
      return action.payload.isSuccess && action.payload.operation === 'CREATE';
    }),
    map((action) => {
      return automatedActionSlice.createdAction({
        automatedActionId: action.payload.updated.answerAutomatedActionId,
        actionId: action.payload.actionId,
      });
    }),
  );
}

function reloadAnswerAutomatedActionsOnRecommendedActionChangedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    filter((action) => {
      if (!action.payload.isSuccess) return false;
      if (action.payload.operation === 'CREATE') return false;
      return Boolean(action.payload.updated.answerAutomatedActionId);
    }),
    map((action) =>
      automatedActionSlice.loadAnswerAutomatedActionsForInstance({
        instanceId: action.payload.instanceId,
      }),
    ),
  );
}

function loadAnswerAutomatedActionsForInstanceEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(automatedActionSlice.loadAnswerAutomatedActionsForInstance.match),
    mergeMap(async (action) => {
      const instanceId = action.payload.instanceId;

      const response = await formsQuery.getAnswerAutomatedActions(client, {
        instanceId,
        questionKeys: state$.value.formInstance.questionKeys,
        issueAnswerDefinitions:
          state$.value.formInstance.issueAnswerDefinitions,
      });

      return response.isSuccess
        ? of(automatedActionSlice.setAutomatedActionsForInstance(response.data))
        : EMPTY;
    }),
    concatAll(),
  );
}
