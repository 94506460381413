/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormActionPlanAction,
  IssueClassification,
} from '@se/data/forms/types.ts';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@seeeverything/ui.primitives/src/components/Accordion/index.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Tooltip } from '@seeeverything/ui.primitives/src/components/Tooltip/Tooltip.tsx';
import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useState } from 'react';
import { CoachingRequirement } from '../../redux/issue/issueSlice.ts';
import { ActionContainer } from '../FormActionPlan/components/ActionContainer.tsx';
import { AddAction } from '../FormActionPlan/components/AddAction.tsx';

const COACHING_TITLE = {
  MANDATORY: 'Coaching Required:',
  RECOMMENDED: 'Coaching Recommended:',
  NONE: 'Issue Identified:',
};

const CONTENT_COLOR = color.format(-0.8);
const ICON_COLOR = color.format(-0.5);

export interface IIssueProps {
  actionsCreated?: FormActionPlanAction[];
  classifications: IssueClassification[];
  coachingRequirement: CoachingRequirement;
  complianceFormLabel: string;
  defaultExpanded?: boolean;
  enableActions: boolean;
  formAnswerSelected: string;
  formQuestionText: string;
  formSectionName: string;
  id: string;
  instanceId: string;
  isLoading: boolean;
  issueActionLabel: string;
  issueActions?: FormActionPlanAction[];
  name: string;
  notes: string;
  notesLabel?: string;
  onLaunchReviewClicked: () => void;
  onViewAction?: (actionId: string) => void;
  reviewActionsText?: string;
  reviewDueDate?: string;
  reviewName?: string;
  reviewStatus?: string;
}

export const Issue: React.FC<IIssueProps> = ({
  actionsCreated,
  classifications,
  coachingRequirement,
  complianceFormLabel,
  defaultExpanded,
  enableActions,
  formAnswerSelected,
  formQuestionText,
  formSectionName,
  id,
  instanceId,
  isLoading,
  issueActionLabel,
  issueActions,
  name,
  notes,
  notesLabel = 'Reviewer Notes',
  onLaunchReviewClicked,
  onViewAction,
  reviewActionsText,
  reviewDueDate,
  reviewName,
  reviewStatus,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

  const momentTz = useMomentTenantTimezone();

  const coachingRequirementColor =
    COLORS.COACHING_REQUIREMENT[coachingRequirement];

  const coachingRequirementText = COACHING_TITLE[coachingRequirement];

  const formSectionNameLabel = formSectionName && `*${formSectionName}*`;

  const formQuestionTextLabel =
    formQuestionText &&
    `${formQuestionText.endsWith('?') ? formQuestionText : `${formQuestionText}:`}`;

  const questionPart = [formSectionNameLabel, formQuestionTextLabel]
    .filter(Boolean)
    .join(' -- ');

  const formAnswerSelectedLabel =
    formAnswerSelected && `**${formAnswerSelected}**`;

  const questionWithAnswer = [questionPart, formAnswerSelectedLabel]
    .filter(Boolean)
    .join(' ');

  const elFormDetails = questionWithAnswer && (
    <div css={styles.formDetailsOuter}>
      <div css={styles.contentInner}>
        <Icons.helpOutline
          fill={ICON_COLOR}
          style={styles.iconPadding}
          tooltip={`${complianceFormLabel} section, question, and response details`}
        />
        <Markdown text={questionWithAnswer} />
      </div>
      <div css={styles.classifications}>
        {classifications.map((classification, index) => (
          <Markdown
            key={`${classification.value}-${index}`}
            color={CONTENT_COLOR}
            size={15}
            text={`- ${classification.value}`}
          />
        ))}
      </div>
    </div>
  );

  const formStatusLabel = [reviewStatus, reviewDueDate]
    .filter(Boolean)
    .join(', ');

  const elLinkedForm = reviewName && (
    <div>
      <div css={styles.contentInnerReview}>
        <Icons.dashboard
          fill={ICON_COLOR}
          style={styles.iconPadding}
          tooltip={'Originating Review'}
        />
        <Text color={CONTENT_COLOR}>{'Review:'}</Text>
        <Button onClick={onLaunchReviewClicked}>
          <div css={styles.linkButton}>
            <Text color={COLORS.BLUE} cursor={'pointer'}>
              {reviewName}
            </Text>
            <Icons.launch />
          </div>
        </Button>
        <Text color={CONTENT_COLOR} ellipsis={true}>
          {formStatusLabel}
        </Text>
      </div>
      {reviewActionsText && (
        <Text color={CONTENT_COLOR} ellipsis={true} style={styles.actionsText}>
          {reviewActionsText}
        </Text>
      )}
    </div>
  );

  const elSummaryContent = (
    <div css={styles.contentInner}>
      <Icons.localLibrary
        fill={coachingRequirementColor}
        style={styles.iconPadding}
        tooltip={'Issue'}
        cursor={'pointer'}
      />
      <div css={styles.summaryText}>
        <Text
          color={coachingRequirementColor}
          ellipsis={true}
          cursor={'pointer'}
        >
          {coachingRequirementText}
        </Text>
        <Text
          color={color.format(-0.8)}
          ellipsis={true}
          style={styles.issueLabel}
          cursor={'pointer'}
          weight={800}
        >
          {name}
        </Text>
      </div>
      {actionsCreated?.length > 0 && (
        <Tooltip
          arrow={true}
          interactive={true}
          title={
            <div
              css={styles.createdActionTooltip}
              onClick={(e) => e.stopPropagation()}
            >
              <Text
                size={15}
                style={styles.createdActionTooltipTitle}
                weight={800}
                color={'white'}
              >{`${str.plural(issueActionLabel)}:`}</Text>
              {actionsCreated.map((a, index) => {
                const subText =
                  a.status === 'Completed' || a.status === 'Cancelled'
                    ? `Assigned to ${a.assignedToName} (${a.status})`
                    : `Assigned to ${a.assignedToName} (Due ${momentTz(a.dueBy).format('D MMM YY')})`;

                return (
                  <div key={index} onClick={() => onViewAction?.(a.id)}>
                    <Markdown
                      key={index}
                      size={15}
                      color={'white'}
                      text={`- ${a.description} -- ${subText}`}
                      tooltip={'Click to View'}
                      style={styles.viewActionTooltip}
                    />
                  </div>
                );
              })}
            </div>
          }
        >
          <div css={styles.actionsCreatedCount}>
            <Icons.actionList fill={'white'} />
            <Text
              size={14}
              color={'white'}
              cursor={'pointer'}
              ellipsis={true}
            >{`${actionsCreated.length} ${str.plural(issueActionLabel, actionsCreated.length)} Created`}</Text>
          </div>
        </Tooltip>
      )}
    </div>
  );

  const elNotes = notes && (
    <div css={styles.contentInner}>
      <Icons.questionAnswerIcon
        fill={ICON_COLOR}
        style={styles.iconPadding}
        tooltip={'Reviewer Notes'}
      />
      <Markdown
        color={CONTENT_COLOR}
        text={notesLabel ? `${notesLabel}: ${notes}` : notes}
      />
    </div>
  );

  const elDetailsContent = (
    <div css={styles.contentOuter}>
      {elLinkedForm}
      {elFormDetails}
      {elNotes}
    </div>
  );

  return (
    <div css={styles.contentContainer}>
      {isLoading && (
        <>
          <div css={styles.issueLoading}>
            <Spinner center={true} />
          </div>
          <div css={styles.issueLoadingMask} />
        </>
      )}
      <Accordion
        id={`issue-${id}`}
        elevation={0}
        isExpanded={isExpanded}
        onChange={(e) => setIsExpanded(e.isExpanded)}
        hideDividers={true}
        isDefaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          style={styles.accordionBase}
          expandedStyle={styles.accordionExpanded}
          contentStyle={styles.accordionContent}
          expandIcon={<Icons.arrowDropDown size={26} />}
        >
          <div css={styles.summaryContent}>
            {elSummaryContent}
            <Text
              color={COLORS.BLUE}
              size={14}
              cursor={'pointer'}
              style={styles.summaryShowHideText}
            >
              {isExpanded ? 'Hide' : 'Show'}
            </Text>
          </div>
        </AccordionSummary>
        <AccordionDetails style={styles.detailsBase}>
          {elDetailsContent}
        </AccordionDetails>
      </Accordion>
      {enableActions && (
        <div css={styles.actionsOuter}>
          {issueActions?.map((action) => (
            <ActionContainer
              key={`CoachingIssueAction-${action.id}`}
              id={action.id}
              source={{ type: 'FORM', instanceId }}
            />
          ))}
          <div css={styles.addActionButton}>
            <span css={styles.itemTrack} />
            <AddAction
              message={
                issueActions?.length > 0
                  ? `Add another ${issueActionLabel.toLowerCase()}.`
                  : `Add a ${issueActionLabel.toLowerCase()}.`
              }
              style={styles.addAction}
              instanceId={instanceId}
              issueId={id}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  accordionBase: {
    backgroundColor: color.format(-0.05),
    border: `1px solid ${color.format(-0.1)}`,
    borderRadius: 4,
    '&$expanded > $expandIcon': {
      transform: 'rotate(-180deg)',
    },
    '&$expanded > $expandIcon span svg': {
      fill: COLORS.BLUE,
    },
    '&:hover': {
      background: color.format(-0.1),
    },
    overflow: 'hidden',
  },
  accordionExpanded: {
    backgroundColor: color.format(-0.05),
    minHeight: 48,
    color: color.format(-0.4),
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      background: color.format(-0.1),
    },
  },
  accordionContent: {
    color: COLORS.BLUE,
    display: 'inline-block' as const,
    textAlign: 'end' as const,
    '&$expanded': {
      margin: '12px 0',
      color: COLORS.BLUE,
    },
  },
  summaryText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    textAlign: 'start',
  }),
  contentContainer: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  }),
  contentOuter: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  }),
  contentInner: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  contentInnerReview: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  formDetailsOuter: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }),
  actionsText: css({
    paddingLeft: 98,
  }),
  classifications: css({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    gap: 5,
  }),
  detailsBase: {
    background: '#fefefe',
    border: `1px solid #cecece`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    padding: '12px 16px',
  },
  iconPadding: {
    paddingRight: 10,
  },
  actionsOuter: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 0 18px',
  }),
  addActionButton: css({
    display: 'flex',
    gap: 12,
  }),
  itemTrack: css({
    marginLeft: 10,
    height: 22,
    minWidth: 22,
    borderLeft: `solid 2px ${color.format(-0.2)}`,
    borderBottom: `solid 2px ${color.format(-0.2)}`,
  }),
  issueLoading: css({
    position: 'absolute',
    inset: 0,
    zIndex: 2,
  }),
  issueLoadingMask: css({
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    backgroundColor: color.format(0.5),
  }),
  linkButton: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  summaryContent: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  }),
  summaryShowHideText: css({
    alignSelf: 'center',
    padding: '0 5px 0 20px',
  }),
  issueLabel: css({
    textWrap: 'wrap',
    overflowWrap: 'anywhere',
  }),
  addAction: css({
    margin: '5px 0 0 -3px',
  }),
  actionsCreatedCount: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginLeft: 10,
    backgroundColor: color.format(-0.3),
    borderRadius: 5,
    border: `solid 1px ${color.format(-0.1)}`,
    padding: '3px 9px 3px 8px',
  }),
  createdActionTooltip: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }),
  createdActionTooltipTitle: css({
    padding: '5px 0 5px 6px',
  }),
  viewActionTooltip: css({
    ':hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
};
