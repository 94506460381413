import { formsQuery } from '@se/data/forms/query/index.ts';
import { FormActionPlanAction } from '@se/data/forms/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import {
  Observable,
  concatAll,
  filter,
  from,
  map,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import {
  ReduxFormInstanceServerLoaded,
  ReduxFormInstanceSubjectChanged,
} from '../form-instance/types.ts';
import { formActionSlice } from '../formAction/index.ts';
import { issueSlice } from '../issue/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formActionPlanSlice from './formActionPlanSlice.ts';

export const formActionPlanEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  loadActionPlanEpic,
  loadActionPlanOnInstanceLoadEpic,
  reloadActionIdOnIssueIdChangedEpic,
  reloadActionPlanOnSubjectChangedEpic,
  upsertV2ActionToPlanEpic,
);

function loadActionPlanOnInstanceLoadEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    map((action) =>
      formActionPlanSlice.loadActionPlan({
        instanceId: action.payload.instanceId,
      }),
    ),
  );
}

function upsertV2ActionToPlanEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    filter((action) =>
      Boolean(
        action.payload.isSuccess &&
          action.payload.updated &&
          action.payload.source.type === 'FORM',
      ),
    ),
    map((action) => {
      const updatedAction = action.payload.updated;
      const timezone = state$.value.tenantState.tenant.configuration.timezone;

      const formActionItem: FormActionPlanAction = {
        id: updatedAction.id,
        formInstance: {
          id: updatedAction.instanceId,
          templateName: '',
        },
        description: updatedAction.description,
        dueBy: updatedAction.dueBy,
        goalId: updatedAction.goalId,
        issueId: updatedAction.issue?.id,
        assignedToName: updatedAction.assignedTo?.name,
        status: updatedAction.status,
        createdAt: updatedAction.createdAt,
        completedAt: updatedAction.completedAt,
      };

      return formActionPlanSlice.upsertActions({
        actions: [formActionItem],
        timezone,
      });
    }),
  );
}

function reloadActionPlanOnSubjectChangedEpic(
  action$: Observable<ReduxFormInstanceSubjectChanged>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/SUBJECT_CHANGED'),
    map((action) =>
      formActionPlanSlice.loadActionPlan({
        instanceId: action.payload.instanceId,
      }),
    ),
  );
}

function loadActionPlanEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formActionPlanSlice.loadActionPlan.match),
    switchMap(async (action) => {
      const instanceId = action.payload.instanceId;
      const personId =
        state$.value.formInstance.instances[action.payload.instanceId]?.subject
          ?.id;
      const timezone = state$.value.tenantState.tenant.configuration.timezone;

      if (!personId) return of(formActionPlanSlice.loaded());

      const response = await formsQuery.getFormInstanceActionPlan(
        client,
        instanceId,
        personId,
        timezone,
      );

      if (!response.isSuccess) return of(formActionPlanSlice.loadError());

      return from([
        formActionPlanSlice.upsertActions({
          actions: response.data.actions,
          timezone,
        }),
        formActionPlanSlice.upsertGoals({
          goals: response.data.goals,
          timezone,
        }),
      ]);
    }),
    concatAll(),
  );
}

function reloadActionIdOnIssueIdChangedEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(issueSlice.answerChangedWithNewIssueId.match),
    filter((action) => {
      const answerId = action.payload.toAnswer.id;

      const issue = state$.value.formIssue.issues.find(
        (i) => i.answerId === answerId,
      );
      if (!issue) return false;

      return Object.values(state$.value.formActionPlanV2.actions).some(
        (a) => a.issueId === issue.id,
      );
    }),
    mergeMap(async (action) => {
      const answerId = action.payload.toAnswer.id;

      const issue = state$.value.formIssue.issues.find(
        (i) => i.answerId === answerId,
      );

      const reloadActionIds = Object.values(
        state$.value.formActionPlanV2.actions,
      )
        .filter((a) => a.issueId === issue.id)
        .map((a) => a.id);

      return formActionPlanSlice.upsertActions({
        actions: (
          await Promise.all(
            reloadActionIds.map(async (id): Promise<FormActionPlanAction> => {
              const response = await formsQuery.getFormAction(client, id);
              if (!response.isSuccess) return;

              return {
                id: response.data.id,
                goalId: response.data.goalId,
                issueId: response.data.issue?.id,
                formInstance: response.data.formInstance && {
                  id: response.data.formInstance.id,
                  templateName: response.data.formInstance.template.name,
                },
                description: response.data.description,
                dueBy: response.data.dueBy,
                assignedToName: response.data.assignedTo?.name,
                createdAt: response.data.createdAt,
                completedAt: response.data.completedAt,
                status: response.data.status,
              };
            }),
          )
        ).filter(Boolean),
        timezone: state$.value.tenantState.tenant.configuration.timezone,
      });
    }),
  );
}
